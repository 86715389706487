export default (theme) => ({
  wrapper: {
    paddingLeft: 116,
    paddingRight: 116,
  },
  contentWrapper: {
    marginTop: 24,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 5,
    paddingBottom: 20,
  },
  textField: {
    backgroundColor: theme.palette.cardBackground,
  },
  generateLinksWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 12,
  },
  inputWrapper: {
    marginTop: 12,
  },
});
