export default (theme) => ({
  tableWrapper: {
    marginTop: 24,
    backgroundColor: theme.palette.cardBackground,
  },
  available: {
    color: '#8C8C8C',
  },
  launched: {
    color: '#3B86FF',
  },
  finished: {
    color: '#FF5358',
  },
  initialized: {
    color: '#3B86FF',
  },
  numberCell: {
    width: 200,
    padding: 12,
  },
  centered: {
    textAlign: 'center',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  refreshWrapper: {
    marginRight: 16,
  },
  uniqueIdCell: {
    textAlign: 'right',
  },
});
