import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import FormField from 'components/FormField';
import validator from 'utils/validator/core';

import { classesShape } from 'utils/shapes/classesShape';

import PinPad from 'components/PinPad';
import formDialogStyles from './FormDialog.styles';
import messages from './FormDialog.messages';

const FormDialog = ({
  intl,
  open,
  onClose,
  onDialogClose,
  title,
  classes,
  onSubmit,
}) => {
  const validators = [{
    fieldName: 'value',
    validators: [
      {
        validatorName: 'maxLength',
        parameters: [255],
      },
    ],
  }];

  return (
    <Dialog open={open} onClose={onDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          pin: '',
        }}
        validateOnChange
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <React.Fragment>
            <DialogContent>
              <FormField
                onBlur={setFieldTouched}
                onChange={setFieldValue}
                errors={errors}
                touched={touched}
                name="pin"
                fullWidth
              >
                <div className={classes.pinPadWrapper}>
                  <PinPad
                    onClick={
                      (nextDigit) => {
                        setFieldValue('pin', `${values.pin}${nextDigit}`);
                      }
                    }
                  />
                </div>
              </FormField>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  onClose();
                  resetForm();
                }}
                color="primary"
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
              <Button
                onClick={handleSubmit}
                color="primary"
              >
                {intl.formatMessage(messages.save)}
              </Button>
            </DialogActions>
          </React.Fragment>
        )
      }
      />
    </Dialog>
  );
};

FormDialog.propTypes = {
  classes: classesShape.isRequired,
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onDialogClose: PropTypes.func,
};

FormDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onDialogClose: () => {},
  title: '',
};

export default withStyles(formDialogStyles)(injectIntl(FormDialog));
