import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { classesShape } from 'utils/shapes/classesShape';
import withPager from 'utils/withPager';
import PageTitle from 'components/PageTitle';
import TablePaginationActions from 'components/TablePaginationActions';
import ActiveSurveys from 'components/ActiveSurveys';
import surveysListStyles from './SurveysList.styles';

import messages from './SurveyList.messages';

const SurveysList = ({
  items, totalItemsCount, page, rowsPerPageOptions, rowsPerPage,
  onChangePage, prevPageDisabled, nextPageDisabled, activeSurveys,
  classes, onGoBack, intl, onSurveySelect, onActiveSurveyClick, onActiveSurveyFinish,
}) => (
  <div>
    <PageTitle
      withGoBack
      onGoBackClick={onGoBack}
    >
      {intl.formatMessage(messages.surveysList)}
    </PageTitle>
    <div className={classes.tableWrapper}>
      <div className={classes.activeSurveysWrapper}>
        <ActiveSurveys
          onActiveSurveyClick={onActiveSurveyClick}
          onActiveSurveyFinish={onActiveSurveyFinish}
          activeSurveys={activeSurveys}
        />
      </div>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head" className={classes.numberCell}>#</TableCell>
              <TableCell variant="head">
                {intl.formatMessage(messages.name)}
              </TableCell>
              <TableCell variant="head" className={classes.buttonCell} />
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" className={classes.numberCell}>
                  {row.id}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell className={classes.buttonCell}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onSurveySelect(row.surveyId)}
                  >
                    {intl.formatMessage(messages.choose)}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalItemsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            disabled: prevPageDisabled,
          }}
          nextIconButtonProps={{
            disabled: nextPageDisabled,
          }}
          onChangePage={onChangePage}
          className={classNames({
            [classes.caption]: true,
          })}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
        />
      </Paper>
    </div>
  </div>
);

SurveysList.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onActiveSurveyClick: PropTypes.func.isRequired,
  onActiveSurveyFinish: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onSurveySelect: PropTypes.func.isRequired,
  activeSurveys: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    organizationUnit: PropTypes.string,
  })),
  classes: classesShape,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  })),
  nextPageDisabled: PropTypes.bool,
  prevPageDisabled: PropTypes.bool,
  onGoBack: PropTypes.func,
};

SurveysList.defaultProps = {
  activeSurveys: [],
  items: [],
  classes: {},
  onGoBack: () => {},
  prevPageDisabled: false,
  nextPageDisabled: false,
};

export default withStyles(surveysListStyles)(withPager()(injectIntl(SurveysList)));
