import { defineMessages } from 'react-intl';

export default defineMessages({
  launchedSurvey: 'Klasa "{organizationUnit}" - ankieta "{surveyName}"',
  go: 'Przejdź',
  finish: 'Zakończ',
  enterPin: 'Wpisz kod pin aby zakończyć ankietę',
  pin: 'pin',
  pinIncorrect: 'Niepoprawny kod pin',
});
