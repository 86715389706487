import { defineMessages } from 'react-intl';

export default defineMessages({
  chooseGender: 'Wybierz płeć',
  insertBirthData: 'Podaj rok i miesiąc urodzenia',
  year: 'Rok',
  month: 'Miesiąc',
  next: 'Rozpocznij ankietę',
  sameCity: 'Czy mieszkasz w tej samej miejscowości co szkoła?',
  yes: 'Tak',
  no: 'Nie',
  city: 'Miasto',
});
