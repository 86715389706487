import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

const FormField = ({
  onChange, name, label, touched, errors, children, onBlur, variant, fullWidth, passErrorStatus,
}) => {
  const isError = touched[name] && errors[name];

  const newChildrenProps = {
    name,
    id: name,
    onBlur: () => onBlur(name, true),
    onChange: (event) => {
      onChange(name, event.target.value);
    },
  };

  if (passErrorStatus) {
    newChildrenProps.withError = !!isError;
  }

  return (
    <FormControl error={!!isError} variant={variant} fullWidth={fullWidth}>
      {
        label ? (
          <InputLabel htmlFor={name}>{label}</InputLabel>
        ) : null
      }
      {
        React.cloneElement(children, newChildrenProps)
      }
      {
        isError ? (
          <FormHelperText>{errors[name]}</FormHelperText>
        ) : null
      }
    </FormControl>
  );
};

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  passErrorStatus: PropTypes.bool,
  variant: PropTypes.string,
};

FormField.defaultProps = {
  fullWidth: false,
  passErrorStatus: false,
  variant: null,
  label: null,
};

export default FormField;
