import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import GradeDataPage from 'components/pages/GradeData';

import { setTitle, setUniqueIdVisibility } from 'containers/store';

import { generateLinks } from './store';

class GradeData extends React.Component {
  static propTypes = {
    generateLinks: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setUniqueIdVisibility: PropTypes.func.isRequired,
    surveyName: PropTypes.string,
  };

  static defaultProps = {
    surveyName: '',
  }

  componentDidMount() {
    this.props.setUniqueIdVisibility(false);
    this.props.setTitle(this.props.surveyName);
  }

  render() {
    return (
      <GradeDataPage
        {...this.props}
        onGoBack={this.props.goBack}
        onGenerate={this.props.generateLinks}
      />
    );
  }
}

const mapDispatchToProps = {
  setTitle,
  generateLinks,
  goBack,
  setUniqueIdVisibility,
};

const mapStateToProps = (state) => ({
  ...state.GradeData,
  surveyName: _get(state, 'Global.selectedSurvey.name', ''),
});

export default connect(mapStateToProps, mapDispatchToProps)(GradeData);
