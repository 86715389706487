export default () => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
  buttonWrapper: {
    flexBasis: '25%',
    margin: '6px',
    borderRadius: 0,
    height: '67px',
    fontSize: '28px',
  },
});
