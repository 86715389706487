import React from 'react';
import { Route } from 'react-router-dom';
import PageLayout from 'components/PageLayout';

const AuthorizedRoute = ({ component: Component, ...rest }) => ( // eslint-disable-line react/prop-types
  <Route
    {...rest}
    render={(props) => (
      <PageLayout>
        <Component {...props} />
      </PageLayout>
    )}
  />
);

export default AuthorizedRoute;
