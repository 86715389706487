import axios from 'axios';
import { push } from 'connected-react-router';
import _get from 'lodash/get';
import { hideLoader } from 'containers/store'; // eslint-disable-line import/no-cycle
import config from '../config';


class ApiManager {
  constructor() {
    this.defaultHost = undefined;
    this.localApiHost = config.localApiHost;
    this.host = this.localApiHost;
    this.axiosInstance = this.getAxiosInstance();
    this.addInterceptors();
    this.dispatch = null;
  }

  static getRequestError(err) {
    if (err.response) {
      return err.response.data;
    }

    if (err.request) {
      return err.request;
    }

    return err.message;
  }

  getAxiosInstance() {
    return axios.create({
      baseURL: this.host,
      withCredentials: true,
      timeout: 5000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
  }

  addInterceptors() {
    this.axiosInstance.interceptors.response.use(
      (response) => Promise.resolve(response),
      (error) => {
        if (error.response && error.response.status === 401) {
          this.dispatch(push('/login'));
          this.dispatch(hideLoader());

          return Promise.resolve({ cancelled: true });
        }

        return Promise.reject(error);
      },
    );
  }

  catchError = (errorResponse, reject) => {
    const error = ApiManager.getRequestError(errorResponse);

    if (!errorResponse.cancelled) {
      reject({ error, code: _get(errorResponse, 'response.status', null) });
    }
  };

  setDispatch(dispatch) {
    if (!this.dispatch) {
      this.dispatch = dispatch;
    }
  }

  request(method, dispatch, endpoint, requestBody, withHeaders) {
    this.setDispatch(dispatch);
    const filledBody = requestBody || {};

    return new Promise((resolve, reject) => {
      this.axiosInstance[method](`${config.apiUrl}/${endpoint}`, filledBody)
        .then((res) => {
          if (withHeaders) {
            resolve({ data: res.data, headers: res.headers });
          } else if (res.status >= 200 && res.status < 300) {
            resolve(res.data);
          } else {
            this.catchError(res, reject);
          }
        })
        .catch((err) => {
          this.catchError(err, reject);
        });
    });
  }
}

export default new ApiManager();
