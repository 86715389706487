import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import { classesShape } from 'utils/shapes/classesShape';
import { themeShape } from 'utils/shapes/themeShape';
import logoSmall from 'assets/img/logo_small.png';
import avatar from 'assets/img/avatar.png';
import { logout, hideSnackbar as hideSnackbarCallback } from 'containers/store';
import pageLayoutStyles from './PageLayout.styles';

import messages from './PageLayout.messages';

class PageLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    hideSnackbar: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    loaderVisible: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    snackbarVisible: PropTypes.bool.isRequired,
    transparentLoaderVisible: PropTypes.bool.isRequired,
    classes: classesShape,
    snackbarMessage: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    theme: themeShape,
    title: PropTypes.string,
    uniqueid: PropTypes.string,
    uniqueIdHidden: PropTypes.bool,
    uniqueIdVisible: PropTypes.bool,
  };

  static defaultProps = {
    uniqueid: null,
    classes: {},
    theme: {},
    snackbarMessage: null,
    title: null,
    uniqueIdVisible: false,
    uniqueIdHidden: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      menuAnchorElement: null,
    };
  }

  handleClick = (event) => {
    this.setState({ menuAnchorElement: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ menuAnchorElement: null });
  };

  handleLogout = () => {
    this.props.logout().then(() => {
      this.handleClose();
    }).catch(() => {
      this.handleClose();
    });
  }

  render() {
    const {
      classes, theme, children, uniqueid, intl, loaderVisible,
      transparentLoaderVisible, title, snackbarVisible, hideSnackbar,
      snackbarMessage, uniqueIdVisible, uniqueIdHidden,
    } = this.props;
    const { menuAnchorElement } = this.state;
    const menuOpened = Boolean(menuAnchorElement);

    return (
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.logoWrapper}>
            <img src={logoSmall} alt="Diagmatic" className={classes.logo} />
          </div>
          <div className={classNames({
            [classes.headerContent]: true,
          })}
          >
            <Typography
              variant="h6"
            >
              {title}
            </Typography>
          </div>
          <div className={classes.userData}>
            {
              uniqueIdVisible && uniqueid ? (
                <div className={classes.idWrapper}>
                  {
                    uniqueIdHidden ? null : (
                      <Typography>
                    ID
                        {' '}
                        {uniqueid}
                      </Typography>
                    )
                  }
                </div>
              ) : (
                <React.Fragment>
                  <div>
                    <Menu
                      id="long-menu"
                      anchorEl={menuAnchorElement}
                      open={menuOpened}
                      onClose={this.handleClose}
                      PaperProps={{
                        style: {
                          width: 200,
                          marginTop: 24,
                          backgroundColor: theme.palette.cardBackground,
                        },
                      }}
                    >
                      <MenuItem onClick={this.handleLogout} selected={false}>
                        {intl.formatMessage(messages.logout)}
                      </MenuItem>
                    </Menu>
                  </div>
                  <div className={classes.avatar} onClick={this.handleClick}>
                    <img src={avatar} alt="avatar" />
                  </div>
                </React.Fragment>
              )
             }
          </div>
        </div>
        {
          loaderVisible || transparentLoaderVisible ? (
            <div className={classNames({
              [classes.loaderWrapper]: true,
              [classes.transparentLoaderWrapper]: transparentLoaderVisible,
            })}
            >
              {
                transparentLoaderVisible ? (
                  <div className={classes.transparentLoaderBg} />
                ) : null
              }
              <div className={classes.progressWrapper}>
                <CircularProgress color="primary" />
              </div>
            </div>
          ) : null
        }
        <div className={classNames({
          [classes.pageContent]: true,
          [classes.loadingPageContent]: loaderVisible,
        })}
        >
          {children}
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={snackbarVisible}
          onClose={hideSnackbar}
          message={snackbarMessage ? intl.formatMessage(snackbarMessage) : null}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  logout,
  hideSnackbar: hideSnackbarCallback,
};

const mapStateToProps = (state) => ({
  ...state.Global,
  uniqueid: _get(state, 'Global.selectedLink.questionId', null),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(pageLayoutStyles)(
    withTheme(
      injectIntl(PageLayout),
    ),
  ),
);
