import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import config from 'config';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormDialog from 'components/FormDialog';

import clock from 'assets/img/clock.png';
import thumbUp from 'assets/img/thumbUp.png';
import warning from 'assets/img/warning.png';
import PageTitle from 'components/PageTitle';
import { classesShape } from 'utils/shapes/classesShape';
import surveyFinishedStyles from './SurveyFinished.styles';

import messages from './SurveyFinished.messages';

class SurveyFinished extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    classes: classesShape,
    surveyId: PropTypes.string,
    type: PropTypes.oneOf(['finished', 'timesUp', 'noConnection', 'error']),
    onEndClick: PropTypes.func,
    onTryAgain: PropTypes.func,
  };

  static defaultProps = {
    type: 'finished',
    classes: {},
    surveyId: '',
    onEndClick: () => { },
    onTryAgain: () => { },
  };

  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  toggleDialog = (opened) => {
    this.setState({
      dialogOpen: opened,
    });
  }

  onPinSubmit = (values, methods) => {
    if (values.pin === config.closeSurveyPin) {
      this.props.onEndClick();
      methods.resetForm();
      this.toggleDialog(false);
    } else {
      methods.setFieldError('pin', this.props.intl.formatMessage(messages.pinIncorrect));
      methods.setFieldValue('pin', '', false);
    }
  }

  render() {
    const {
      classes, type, intl, surveyId, onTryAgain,
    } = this.props;

    let message = messages.description;
    let icon = thumbUp;
    let title = messages.surveyFinished;

    if (type === 'timesUp') {
      message = messages.timesUp;
      icon = clock;
    } else if (type === 'noConnection') {
      message = messages.noConnection;
      icon = warning;
      title = messages.noConnectionTitle;
    } else if (type === 'error') {
      icon = warning;
      title = messages.error;
    }

    return (
      <div className={classes.wrapper}>
        <PageTitle>
          {intl.formatMessage(title)}
        </PageTitle>
        <div className={classes.contentWrapper}>
          <div className={classes.iconWrapper}>
            {
            type !== 'noConnection' && type !== 'error' ? (
              <div
                onClick={() => { this.toggleDialog(true); }}
              >
                <img src={icon} alt="finished" />
              </div>
            ) : (
              <img src={icon} alt="finished" />
            )
          }
          </div>
          {
            type !== 'error' ? (
              <React.Fragment>
                <div className={classes.separator} />
                <div className={classes.descriptionWrapper}>
                  <Typography variant="h6">
                    {intl.formatMessage(message)}
                  </Typography>
                  {
                    type === 'finished' || type === 'timesUp' ? (
                      <Typography variant="h6">
                        {surveyId}
                      </Typography>
                    ) : null
                  }
                </div>
              </React.Fragment>
            ) : null
          }
          {
          type === 'noConnection' ? (
            <Button
              variant="contained"
              color="primary"
              onClick={onTryAgain}
              fullWidth
            >
              {intl.formatMessage(messages.tryAgain)}
            </Button>
          ) : null
        }
        </div>
        <FormDialog
          title={intl.formatMessage(messages.enterPin)}
          label={intl.formatMessage(messages.pin)}
          onClose={() => { this.toggleDialog(false); }}
          onSubmit={this.onPinSubmit}
          open={this.state.dialogOpen}
        />
      </div>
    );
  }
}
export default withStyles(surveyFinishedStyles)(injectIntl(SurveyFinished));
