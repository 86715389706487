import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

import { classesShape } from 'utils/shapes/classesShape';
import pageTitleStyles from './PageTitle.styles';

const PageTitle = ({
  children, classes, withGoBack, onGoBackClick,
}) => (
  <div className={classes.titleWrapper}>
    {
      withGoBack ? (
        <div className={classes.goBackIconWrapper}>
          <IconButton onClick={onGoBackClick}>
            <ArrowBackIcon
              color="inherit"
              fontSize="default"
            />
          </IconButton>
        </div>
      ) : null
    }
    <Typography variant="h6">
      {children}
    </Typography>
  </div>
);

PageTitle.propTypes = {
  children: PropTypes.string.isRequired,
  classes: classesShape,
  withGoBack: PropTypes.bool,
  onGoBackClick: PropTypes.func,
};

PageTitle.defaultProps = {
  classes: {},
  withGoBack: false,
  onGoBackClick: () => {},
};

export default withStyles(pageTitleStyles)(PageTitle);
