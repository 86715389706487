import { defineMessages } from 'react-intl';

export default defineMessages({
  surveyFinished: 'Ankieta została zakończona',
  noConnectionTitle: 'Błąd połączenia internetowego',
  description: 'Dziękujemy za udział w ankiecie',
  timesUp: 'Upłynął czas badania',
  noConnection: 'Poproś obsługę o pomoc!',
  end: 'Zakończ',
  error: 'Wystąpił błąd',
  tryAgain: 'Spróbuj ponownie',
  enterPin: 'Wpisz kod pin aby zakończyć ankietę',
  pin: 'pin',
  pinIncorrect: 'Niepoprawny kod pin',
});
