import { replace } from 'connected-react-router';
import {
  showTransparentLoader, hideLoader, showSnackbar, stopCounter, setTitle,
  setUniqueIdHidden,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import { incrementIndex, finishSessions } from 'containers/QuestionPage/store';
import { onSubmit } from 'containers/UserData/store';

export const initialState = {
  isLoadedPage: false,
  type: null,
  pageType: null,
  savedValues: {},
  userSavedValues: {},
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEY_FINISHED/LOAD_PAGE_SUCCESS',
  SET_ACTION_TYPE: 'SURVEY_FINISHED/SET_ACTION_TYPE',
  SAVE_VALUES: 'SURVEY_FINISHED/SAVE_VALUES',
  USER_SAVE_VALUES: 'SURVEY_FINISHED/USER_SAVE_VALUES',
  SET_PAGE_TYPE: 'SURVEY_FINISHED/SET_PAGE_TYPE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
      };
    }

    case actionTypes.SET_ACTION_TYPE: {
      return {
        ...state,
        type: action.screenType,
      };
    }

    case actionTypes.SAVE_VALUES: {
      return {
        ...state,
        savedValues: action.values,
      };
    }

    case actionTypes.USER_SAVE_VALUES: {
      return {
        ...state,
        userSavedValues: action.values,
      };
    }

    case actionTypes.SET_PAGE_TYPE: {
      return {
        ...state,
        pageType: action.pageType,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = () => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
});

const setActionType = (screenType) => ({
  type: actionTypes.SET_ACTION_TYPE,
  screenType,
});

const setPageType = (pageType) => ({
  type: actionTypes.SET_PAGE_TYPE,
  pageType,
});

const setSavedValues = (values) => ({
  type: actionTypes.SAVE_VALUES,
  values,
});

const setUserSavedValues = (values) => ({
  type: actionTypes.USER_SAVE_VALUES,
  values,
});

export const onTryAgain = () => (dispatch, getStore) => {
  const store = getStore();

  const sessionId = store.Global.sessionId;
  const uniqueId = store.Global.selectedLink.questionId;
  const currentIndex = store.QuestionPage.currentIndex;
  const questions = store.QuestionPage.questions;
  const values = store.SurveyFinished.savedValues;
  const pageType = store.SurveyFinished.pageType;
  const userValues = store.SurveyFinished.userSavedValues;

  if (pageType === 'userData') {
    dispatch(onSubmit(userValues));
  } else {
    const body = {
      data: {
        uniqueid: uniqueId,
        answer_id: values.answer,
        session_id: sessionId,
        question_id: questions[currentIndex].id,
      },
    };

    dispatch(showTransparentLoader());

    ApiManager.request('post', dispatch, 'results', body).then(() => {
      if (currentIndex + 1 === questions.length) {
        dispatch(finishSessions()).then(() => {
          dispatch(setUniqueIdHidden(true));
          dispatch(setTitle());
          dispatch(stopCounter());
          dispatch(setActionType('finished'));
        }).catch((error) => {
          if (isBadRequest(error)) {
            dispatch(showSnackbar(snackbarMessages.wrongData));
          } else {
            dispatch(showSnackbar(snackbarMessages.globalError));
          }

          dispatch(hideLoader());
        });
      } else {
        dispatch(incrementIndex());
        dispatch(replace('/questionPage'));
      }

      dispatch(hideLoader());
    }).catch((error) => {
      if (error.code === null) { // no connection
        dispatch(showSnackbar(snackbarMessages.globalError));
      } else if (error.code === 418) { // times up
        setActionType('timesUp');
      } else if (error.code === 423) {
        setActionType('finished');
      } else if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      }

      dispatch(hideLoader());
    });
  }
};

export const loadPageData = (routeState) => (dispatch, getStore) => {
  dispatch(setActionType(routeState.type));
  dispatch(setPageType(routeState.page));
  dispatch(setSavedValues(routeState.savedValues));
  dispatch(setUserSavedValues(routeState.userSavedValues));
  dispatch(loadPageSuccess());

  const store = getStore();
  const type = store.SurveyFinished.type;

  if (type === 'finished') {
    dispatch(setTitle());
    dispatch(setUniqueIdHidden(true));
  }
};
