import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _find from 'lodash/find';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { classesShape } from 'utils/shapes/classesShape';
import withPager from 'utils/withPager';
import TablePaginationActions from 'components/TablePaginationActions';
import PageTitle from 'components/PageTitle';
import surveysLinksStyles from './SurveysLinks.styles';

import messages from './SurveyLinks.messages';

const getStatus = (status, classes, intl) => {
  let nameMessage = '';
  let className = null;

  switch (status) {
    case 'READY': {
      nameMessage = messages.available;
      className = classes.available;
      break;
    }

    case 'STARTED': {
      nameMessage = messages.launched;
      className = classes.launched;
      break;
    }

    case 'ENDED': {
      nameMessage = messages.finished;
      className = classes.finished;
      break;
    }

    case 'INITIALIZED': {
      nameMessage = messages.initialized;
      className = classes.initialized;
      break;
    }

    default: {
      nameMessage = null;
      break;
    }
  }

  return (
    <div className={className}>
      {intl.formatMessage(nameMessage)}
    </div>
  );
};

const SurveysLinks = ({
  classes, onGoBack, items, totalItemsCount,
  page, rowsPerPageOptions, rowsPerPage, onChangePage, prevPageDisabled,
  nextPageDisabled, intl, onRefresh, onStart,
}) => {
  const buttonDisabled = !_find(items, (el) => el.status === 'READY');

  return (
    <div>
      <PageTitle
        withGoBack
        onGoBackClick={onGoBack}
      >
        {intl.formatMessage(messages.surveyLinks)}
      </PageTitle>
      <div className={classes.buttonsWrapper}>
        <div className={classes.refreshWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => onRefresh()}
          >
            {intl.formatMessage(messages.refresh)}
          </Button>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onStart()}
          disabled={buttonDisabled}
        >
          {intl.formatMessage(messages.launch)}
        </Button>
      </div>
      <div className={classes.tableWrapper}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell variant="head" className={classes.numberCell}>
                  {intl.formatMessage(messages.examined)}
                </TableCell>
                <TableCell variant="head" className={classes.centered}>
                  {intl.formatMessage(messages.status)}
                </TableCell>
                <TableCell variant="head" className={classes.centered}>
                  {intl.formatMessage(messages.progress)}
                </TableCell>
                <TableCell variant="head" className={classes.uniqueIdCell}>
                  {intl.formatMessage(messages.questionId)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row" className={classes.numberCell}>
                      {row.id}
                    </TableCell>
                    <TableCell className={classes.centered}>
                      {getStatus(row.status, classes, intl)}
                    </TableCell>
                    <TableCell className={classes.centered}>
                      {
                        intl.formatMessage(messages.surveyProgress, {
                          currentQuestion: row.currentQuestion,
                          overallQuestion: row.overallQuestion,
                        })
                      }
                    </TableCell>
                    <TableCell className={classes.uniqueIdCell}>{row.questionId}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            rowsPerPageOptions={rowsPerPageOptions}
            count={totalItemsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            ActionsComponent={TablePaginationActions}
            backIconButtonProps={{
              disabled: prevPageDisabled,
            }}
            nextIconButtonProps={{
              disabled: nextPageDisabled,
            }}
            onChangePage={onChangePage}
            className={classNames({
              [classes.caption]: true,
            })}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
          />
        </Paper>
      </div>
    </div>
  );
};

SurveysLinks.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
  classes: classesShape,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    questionId: PropTypes.string,
    status: PropTypes.oneOf(['READY', 'STARTED', 'ENDED', 'INITIALIZED']),
  })),
  nextPageDisabled: PropTypes.bool,
  prevPageDisabled: PropTypes.bool,
  onGoBack: PropTypes.func,
};

SurveysLinks.defaultProps = {
  items: [],
  classes: {},
  onGoBack: () => {},
  prevPageDisabled: false,
  nextPageDisabled: false,
};

export default withStyles(surveysLinksStyles)(withPager()(injectIntl(SurveysLinks)));
