import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import _get from 'lodash/get';
import SurveysLinksPage from 'components/pages/SurveysLinks';
import { historyShape } from 'utils/shapes/router.shapes';

import { setTitle, setUniqueIdVisibility } from 'containers/store';

import { loadPageData, onRefresh, onStart } from './store';

class SurveysLinks extends React.Component {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
    history: historyShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    loadPageData: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setUniqueIdVisibility: PropTypes.func.isRequired,
    surveyName: PropTypes.string,
  };

  static defaultProps = {
    surveyName: '',
  }

  componentDidMount() {
    this.props.setUniqueIdVisibility(false);
    this.props.setTitle(this.props.surveyName);
    this.props.loadPageData();
  }

  render() {
    return this.props.isLoadedPage ? (
      <SurveysLinksPage
        {...this.props}
        onGoBack={this.props.goBack}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  loadPageData,
  setTitle,
  onRefresh,
  onStart,
  goBack,
  setUniqueIdVisibility,
};

const mapStateToProps = (state) => ({
  ...state.SurveysLinks,
  surveyName: _get(state, 'Global.selectedSurvey.name', ''),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveysLinks);
