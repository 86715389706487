export default (theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  inputWrapper: {
    width: 222,
    height: 192,
    border: `2px solid ${theme.palette.background.main}`,
    borderRadius: 5,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  activeInputWrapper: {
    borderColor: theme.palette.primary.main,
  },
  withError: {
    borderColor: theme.palette.error.main,
  },
  nameWrapper: {
    textAlign: 'center',
    marginTop: 24,
  },
  checkWrapper: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});
