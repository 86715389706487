import { replace } from 'connected-react-router';
import { showTransparentLoader, hideLoader, showSnackbar } from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

export const initialState = {
  initialValues: {
    login: '',
  },
};

export const actionTypes = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const onSendClick = (values) => (dispatch) => {
  dispatch(showTransparentLoader());

  const requestBody = {
    data: {
      email: values.login,
    },
  };

  ApiManager.request('post', dispatch, 'forgot_password', requestBody).then(() => {
    dispatch(showSnackbar(snackbarMessages.succesfullyResetedPassword));
    dispatch(hideLoader());
    dispatch(replace('/login'));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
