import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginPage from 'components/pages/Login';

import { login, loadPageData } from './store';

class Login extends React.Component {
  static propTypes = {
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.loadPageData();
  }

  render() {
    return this.props.isLoadedPage ? (
      <LoginPage
        {...this.props}
        onLoginClick={this.props.login}
      />
    ) : null;
  }
}


const mapDispatchToProps = {
  login,
  loadPageData,
};

const mapStateToProps = (state) => ({
  ...state.Login,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
