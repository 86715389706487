import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import getRouteState from 'utils/getRouteState';
import { historyShape } from 'utils/shapes/router.shapes';
import SurveyFinishedPage from 'components/pages/SurveyFinished';

import {
  setTitle, logout, setUniqueIdVisibility,
} from 'containers/store';
import { loadPageData, onTryAgain } from './store';

class SurveyFinished extends React.Component {
  static propTypes = {
    history: historyShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setUniqueIdVisibility: PropTypes.func.isRequired,
    surveyName: PropTypes.string,
    onTryAgain: PropTypes.func,
  };

  static defaultProps = {
    surveyName: '',
    onTryAgain: () => {},
  }

  componentDidMount() {
    this.props.setUniqueIdVisibility(true);
    this.props.setTitle(this.props.surveyName);
    const routeState = getRouteState(this.props.history);
    this.props.loadPageData(routeState);
  }

  onEndClick = () => {
    this.props.logout().then(() => {
      // this.props.push('/login');
      localStorage.clear();
      window.location.pathname = '/';
    }).catch(() => {
      // this.props.push('/login');
      localStorage.clear();
      window.location.pathname = '/';
    });
  }

  render() {
    return this.props.isLoadedPage ? (
      <SurveyFinishedPage
        {...this.props}
        onEndClick={this.onEndClick}
        onTryAgain={this.props.onTryAgain}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  loadPageData,
  setTitle,
  push,
  logout,
  setUniqueIdVisibility,
  onTryAgain,
};

const mapStateToProps = (state) => ({
  ...state.SurveyFinished,
  surveyName: _get(state, 'Global.selectedSurvey.name', ''),
  surveyId: _get(state, 'Global.selectedLink.questionId', ''),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveyFinished));
