import { goBack } from 'connected-react-router';
import _findIndex from 'lodash/findIndex';
import {
  showLoader, hideLoader, showSnackbar,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';

import { finishActiveSurvey, goToActiveSurvey } from 'containers/Dashboard/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
  originalActiveSurveys: [],
  activeSurveys: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEYS_LIST/LOAD_PAGE_SUCCESS',
  SET_ITEMS: 'SURVEYS_LIST/SET_ITEMS',
  UNSET_ACTIVE_SURVEYS: 'SURVEYS_LIST/UNSET_ACTIVE_SURVEYS',
};

const generateItems = (items) => items.map((el, key) => ({
  id: key + 1,
  surveyId: el.survey_id,
  name: el.name,
}));

const generateActiveSurveys = (items) => items.map((el) => ({
  name: el.survey.name,
  id: el.id,
  organizationUnit: el.organization_unit_code,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: generateItems(action.responses.surveyList.data),
        originalActiveSurveys: action.responses.activeSurveys.data,
        activeSurveys: generateActiveSurveys(action.responses.activeSurveys.data),
      };
    }

    case actionTypes.UNSET_ACTIVE_SURVEYS: {
      const activeSurveys = [...state.activeSurveys];
      const originalActiveSurveys = [...state.originalActiveSurveys];
      const itemIndex = _findIndex(activeSurveys, (el) => el.id === action.id);
      const originalItemIndex = _findIndex(originalActiveSurveys, (el) => el.id === action.id);
      activeSurveys.splice(itemIndex, 1);
      originalActiveSurveys.splice(originalItemIndex, 1);

      return {
        ...state,
        activeSurveys,
        originalActiveSurveys,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const unsetActiveSurveys = (id) => ({
  type: actionTypes.UNSET_ACTIVE_SURVEYS,
  id,
});

const loadSurveys = (organizationId) => (dispatch) => ApiManager.request('get', dispatch, `organization/${organizationId}/surveys`);

const getActiveSurveys = () => (dispatch) => ApiManager.request('get', dispatch, 'surveys');

export const onActiveSurveyClick = (id) => (dispatch, getStore) => {
  const store = getStore();

  dispatch(goToActiveSurvey(id, store.SurveysList.originalActiveSurveys, store.Dashboard.items));
};

export const onActiveSurveyFinish = (id) => (dispatch) => {
  dispatch(finishActiveSurvey(id)).then(() => {
    dispatch(unsetActiveSurveys(id));
  }).catch(() => {});
};

export const loadPageData = () => (dispatch, getStore) => {
  dispatch(showLoader());

  const store = getStore();
  const organizationId = store.Global.selectedOrganization.organizationId;

  PromiseAll({
    surveyList: dispatch(loadSurveys(organizationId)),
    activeSurveys: dispatch(getActiveSurveys()),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses));
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
    dispatch(goBack());
  });
};
