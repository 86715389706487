import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';

import noResults from 'assets/img/noResults.png';

import { classesShape } from 'utils/shapes/classesShape';
import withPager from 'utils/withPager';
import TablePaginationActions from 'components/TablePaginationActions';
import ActiveSurveys from 'components/ActiveSurveys';
import dashboardStyles from './Dashboard.styles';
import messages from './Dashboard.messages';

class Dashboard extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    totalItemsCount: PropTypes.number.isRequired,
    onActiveSurveyClick: PropTypes.func.isRequired,
    onActiveSurveyFinish: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChoose: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func.isRequired,
    activeSurveys: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      organizationUnit: PropTypes.string,
    })),
    classes: classesShape,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      address: PropTypes.string,
    })),
    nextPageDisabled: PropTypes.bool,
    prevPageDisabled: PropTypes.bool,
  };

  static defaultProps = {
    activeSurveys: [],
    items: [],
    classes: {},
    prevPageDisabled: false,
    nextPageDisabled: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchingMode: false,
      searchPhrase: '',
      searchSubmitted: false,
    };
  }

  onSearchFocus = () => {
    this.setState({
      searchingMode: true,
    });
  }

  onSearchChange = (event) => {
    this.setState({
      searchPhrase: event.target.value,
      searchSubmitted: false,
    });
  }

  onClearClick = () => {
    this.props.onSearchSubmit('', this.resetState);
  }

  resetState = () => {
    this.setState({
      searchPhrase: '',
      searchingMode: false,
      searchSubmitted: false,
    });
  }

  cancelSearchMode = () => {
    this.setState({
      searchingMode: false,
    });
  }

  setSubmitted = () => {
    this.setState({
      searchingMode: false,
      searchSubmitted: true,
    });
  }

  onSearchSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    this.props.onSearchSubmit(this.state.searchPhrase, this.setSubmitted);
  }

  render() {
    const {
      items, totalItemsCount, page, classes, activeSurveys,
      rowsPerPageOptions, rowsPerPage, onChoose, onActiveSurveyClick,
      onChangePage, prevPageDisabled, nextPageDisabled, intl, onActiveSurveyFinish,
    } = this.props;

    return (
      <div className={classes.wrapper}>
        {
          !this.state.searchingMode ? (
            <ActiveSurveys
              onActiveSurveyClick={onActiveSurveyClick}
              onActiveSurveyFinish={onActiveSurveyFinish}
              activeSurveys={activeSurveys}
            />
          ) : null
        }
        <form onSubmit={this.onSearchSubmit}>
          <div className={classNames({
            [classes.searchWrapper]: true,
            [classes.searchActiveWrapper]: this.state.searchingMode,
          })}
          >
            <div className={classes.searchIconWrapper}>
              {
                this.state.searchingMode ? (
                  <IconButton onClick={this.cancelSearchMode}>
                    <ArrowBackIcon />
                  </IconButton>
                ) : (
                  <SearchIcon />
                )
              }
            </div>
            <div className={classes.inpuWrapper}>
              <Input
                placeholder={intl.formatMessage(messages.findSchool)}
                onFocus={this.onSearchFocus}
                onChange={this.onSearchChange}
                value={this.state.searchPhrase}
                fullWidth
                disableUnderline
              />
            </div>
            <div className={classes.searchSubmitWrapper}>
              {
                this.state.searchingMode && this.state.searchPhrase !== '' ? (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.onSearchSubmit}
                    >
                      {intl.formatMessage(messages.filter)}
                    </Button>
                  </React.Fragment>
                ) : null
              }
              {
                this.state.searchPhrase !== '' ? (
                  <div className={classes.clearWrapper}>
                    <IconButton onClick={this.onClearClick}>
                      <ClearIcon />
                    </IconButton>
                  </div>
                ) : null
              }
            </div>
          </div>
        </form>
        {
          this.state.searchingMode && !this.state.searchSubmitted ? null : (
            <React.Fragment>
              {
                items.length > 0 ? (
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head" className={classes.numberCell}>#</TableCell>
                          <TableCell variant="head">
                            {intl.formatMessage(messages.schoolName)}
                          </TableCell>
                          <TableCell variant="head">
                            {intl.formatMessage(messages.address)}
                          </TableCell>
                          <TableCell variant="head" className={classes.buttonCell} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row" className={classes.numberCell}>
                              {row.id}
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell className={classes.buttonCell}>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => onChoose(row.organizationId)}
                              >
                                {intl.formatMessage(messages.go)}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      ActionsComponent={TablePaginationActions}
                      rowsPerPageOptions={rowsPerPageOptions}
                      component="div"
                      count={totalItemsCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      backIconButtonProps={{
                        disabled: prevPageDisabled,
                      }}
                      nextIconButtonProps={{
                        disabled: nextPageDisabled,
                      }}
                      onChangePage={onChangePage}
                      className={classNames({
                        [classes.caption]: true,
                      })}
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
                    />
                  </Paper>
                ) : null
              }
              {
                this.state.searchSubmitted && items.length === 0 ? (
                  <div className={classes.noResultsWrapper}>
                    <img src={noResults} alt="" />
                    <div className={classes.noResultTextWrapper}>
                      <Typography variant="h5">
                        {intl.formatMessage(messages.noResults)}
                      </Typography>
                    </div>
                  </div>
                ) : null
              }
            </React.Fragment>
          )
        }
      </div>
    );
  }
}

export default withStyles(dashboardStyles)(withPager()(injectIntl(Dashboard)));
