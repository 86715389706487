export default (theme) => ({
  searchWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 24,
    height: 48,
    backgroundColor: theme.palette.background.main,
    borderRadius: 5,
    paddingLeft: 24,
    paddingRight: 24,
  },
  searchActiveWrapper: {
    paddingLeft: 12,
  },
  searchIconWrapper: {
    marginRight: 24,
  },
  caption: {
    color: theme.palette.text.primary,
  },
  numberCell: {
    width: 10,
    padding: 12,
  },
  buttonCell: {
    width: 95,
  },
  searchSubmitWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  clearWrapper: {
    marginLeft: 6,
  },
  noResultsWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  noResultTextWrapper: {
    marginTop: 12,
  },
  inpuWrapper: {
    width: '100%',
    paddingRight: 12,
  },
  wrapper: {
    height: 'calc(100% - 92px)',
  },
});
