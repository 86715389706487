import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';
import _find from 'lodash/find';
import DashboardPage from 'components/pages/Dashboard';

import pageTitles from 'utils/pageTitles';
import { setTitle, setSelectedOrganization, setUniqueIdVisibility } from 'containers/store';
import {
  loadPageData, onSearchSubmit, onActiveSurveyClick, onActiveSurveyFinish,
} from './store';

class Dashboard extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    loadPageData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    setSelectedOrganization: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setUniqueIdVisibility: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setUniqueIdVisibility(false);
    this.props.setTitle(this.props.intl.formatMessage(pageTitles.dashboard));
    this.props.loadPageData();
  }

  onChoose = (id) => {
    const selectedOrganization = _find(this.props.items, (el) => el.organizationId === id);
    this.props.setSelectedOrganization(selectedOrganization);
    this.props.push('/surveysList');
  }

  render() {
    return this.props.isLoadedPage ? (
      <DashboardPage
        {...this.props}
        onChoose={this.onChoose}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  loadPageData,
  setTitle,
  push,
  onSearchSubmit,
  setSelectedOrganization,
  onActiveSurveyClick,
  onActiveSurveyFinish,
  setUniqueIdVisibility,
};

const mapStateToProps = (state) => ({
  ...state.Dashboard,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard));
