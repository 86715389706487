export default (theme) => ({
  wrapper: {
    width: 468,
    margin: 'auto',
  },
  contentWrapper: {
    border: `2px solid ${theme.palette.background.main}`,
    borderRadius: 5,
    padding: 48,
  },
  separator: {
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.background.main,
  },
  descriptionWrapper: {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 24,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
});
