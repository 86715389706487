import React from 'react';
import PropTypes from 'prop-types';
import _forEach from 'lodash/forEach';
import _isEqual from 'lodash/isEqual';

const rowsPerPage = 10;

function withPager() {
  return (WrappedComponent) => {
    class WithPager extends React.Component {
      static propTypes = {
        items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
      };

      static filterItems = (items, page, itemsPerPage) => {
        const filteredItems = [];
        const startIndex = page * itemsPerPage;
        const endIndex = (page + 1) * itemsPerPage;

        _forEach(items, (el, key) => {
          if (key >= startIndex && key < endIndex) {
            filteredItems.push(el);
          }
        });

        return filteredItems;
      };

      static isNextPageDisabled =
        (numberOfItems, page, itemsPerPage) => numberOfItems / ((page + 1) * itemsPerPage) <= 1

      static getDerivedStateFromProps(props, state) {
        if (!_isEqual(state.originalItems, props.items)) {
          return {
            page: 0,
            prevPageDisabled: true,
            nextPageDisabled: WithPager.isNextPageDisabled(props.items.length, 0, rowsPerPage),
            filteredItems: WithPager.filterItems(props.items, 0, rowsPerPage),
            originalItems: props.items,
          };
        }

        return null;
      }

      constructor(props) {
        super(props);

        this.state = {
          page: 0,
          prevPageDisabled: true,
          nextPageDisabled: WithPager.isNextPageDisabled(props.items.length, 0, rowsPerPage),
          filteredItems: WithPager.filterItems(props.items, 0, rowsPerPage),
          originalItems: props.items,
        };
      }

      onChangePage = (event, page) => {
        const { items } = this.props;

        this.setState({
          page,
          prevPageDisabled: page < 1,
          nextPageDisabled: WithPager.isNextPageDisabled(items.length, page, rowsPerPage),
          filteredItems: WithPager.filterItems(items, page, rowsPerPage),
        });
      }

      render() {
        const {
          page, prevPageDisabled, nextPageDisabled, filteredItems,
        } = this.state;
        const { items } = this.props;

        return (
          <WrappedComponent
            {...this.props}
            items={filteredItems}
            page={page}
            totalItemsCount={items.length}
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            onChangePage={this.onChangePage}
            prevPageDisabled={prevPageDisabled}
            nextPageDisabled={nextPageDisabled}
          />
        );
      }
    }

    return WithPager;
  };
}

export default withPager;
