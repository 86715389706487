import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import female from 'assets/img/female.png';
import male from 'assets/img/male.png';
import { classesShape } from 'utils/shapes/classesShape';
import genderPickerStyles from './GenderPicker.styles';

import messages from './GenderPicker.messages';

const GenderPicker = ({
  classes, selected, onChange, intl, withError,
}) => {
  const options = [{
    id: 'female',
    name: intl.formatMessage(messages.female),
    image: female,
  }, {
    id: 'male',
    name: intl.formatMessage(messages.male),
    image: male,
  }];

  return (
    <div className={classes.wrapper}>
      {
        options.map((el) => (
          <div
            className={classNames({
              [classes.inputWrapper]: true,
              [classes.activeInputWrapper]: selected === el.id,
              [classes.withError]: withError,
            })}
            onClick={() => onChange({ target: { value: el.id } })}
            key={el.id}
          >
            {
              selected === el.id ? (
                <div className={classes.checkWrapper}>
                  <CheckCircleIcon color="primary" />
                </div>
              ) : null
            }
            <div>
              <div className={classes.imageWrapper}>
                <img src={el.image} alt={el.name} />
              </div>
              <div className={classes.nameWrapper}>
                <Typography variant="body1">
                  {el.name}
                </Typography>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  );
};

GenderPicker.propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: classesShape,
  selected: PropTypes.oneOf(['male', 'female']),
  withError: PropTypes.bool,
};

GenderPicker.defaultProps = {
  selected: null,
  classes: {},
  withError: false,
};

export default withStyles(genderPickerStyles)(injectIntl(GenderPicker));
