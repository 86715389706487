import greaterThan from './validators/greaterThan';
import isNumber from './validators/isNumber';
import matchRegex from './validators/matchRegex';
import maxLength from './validators/maxLength';
import minLength from './validators/minLength';
import notEmpty from './validators/notEmpty';
import matchOtherField from './validators/matchOtherField';
import lowerOrEqualTo from './validators/lowerOrEqualTo';
import maxDecimalPlaces from './validators/maxDecimalPlaces';
import higherOrEqualTo from './validators/higherOrEqualTo';

export default {
  greaterThan,
  isNumber,
  matchRegex,
  maxLength,
  minLength,
  notEmpty,
  matchOtherField,
  lowerOrEqualTo,
  maxDecimalPlaces,
  higherOrEqualTo,
};
