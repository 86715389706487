import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import QuestionPageComponent from 'components/pages/QuestionPage';

import { setTitle, setUniqueIdVisibility } from 'containers/store';

import { loadPageData, onSubmit, clearStore } from './store';

class QuestionPage extends React.Component {
  static propTypes = {
    clearStore: PropTypes.func.isRequired,
    currentIndex: PropTypes.number.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    setTitle: PropTypes.func.isRequired,
    setUniqueIdVisibility: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.props.clearStore();
  }

  componentDidMount() {
    this.props.setUniqueIdVisibility(true);
    this.props.setTitle('');
    this.props.loadPageData();
  }

  render() {
    const currentQuestion = this.props.questions[this.props.currentIndex] || {};

    return this.props.isLoadedPage ? (
      <QuestionPageComponent
        {...this.props}
        question={currentQuestion.question}
        image={currentQuestion.image}
        answers={currentQuestion.answers}
        progressStatus={currentQuestion.progressStatus}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  loadPageData,
  setTitle,
  onSubmit,
  setUniqueIdVisibility,
  clearStore,
};

const mapStateToProps = (state) => ({
  ...state.QuestionPage,
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage);
