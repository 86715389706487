import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { actionTypes as globalActionTypes, reducer as Global } from 'containers/store';

// page reducers
import { reducer as Login } from 'containers/Login/store';
import { reducer as ForgotPassword } from 'containers/ForgotPassword/store';
import { reducer as Dashboard } from 'containers/Dashboard/store';
import { reducer as SurveysList } from 'containers/SurveysList/store';
import { reducer as GradeData } from 'containers/GradeData/store';
import { reducer as SurveysLinks } from 'containers/SurveysLinks/store';
import { reducer as UserData } from 'containers/UserData/store';
import { reducer as SurveyFinished } from 'containers/SurveyFinished/store';
import { reducer as QuestionPage } from 'containers/QuestionPage/store';
import { reducer as ResetPassword } from 'containers/ResetPassword/store';

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  Global,
  Login,
  ForgotPassword,
  Dashboard,
  SurveysList,
  GradeData,
  SurveysLinks,
  UserData,
  SurveyFinished,
  QuestionPage,
  ResetPassword,
});

export const rootReducer = (history) => (state, action) => {
  let newState = { ...state };

  if (action.type === globalActionTypes.CLEAR_APP_STORE) {
    // clear store
    newState = undefined;
  }

  return appReducer(history)(newState, action);
};
