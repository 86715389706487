import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import FormField from 'components/FormField';
import { classesShape } from 'utils/shapes/classesShape';
import validator from 'utils/validator/core';
import PageTitle from 'components/PageTitle';
import gradeDataStyles from './GradeData.styles';

import messages from './GradeData.messages';

const validators = [
  {
    fieldName: 'gradeName',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
  {
    fieldName: 'count',
    validators: [
      {
        validatorName: 'notEmpty',
      },
      {
        validatorName: 'isNumber',
      },
      {
        validatorName: 'lowerOrEqualTo',
        parameters: [50],
      },
      {
        validatorName: 'greaterThan',
        parameters: [1],
      },
      {
        validatorName: 'maxDecimalPlaces',
        parameters: [0],
        customErrorMessage: messages.mustBeInteger,
      },
    ],
  },
];

const GradeData = ({
  classes, onGoBack, onGenerate, intl, initialValues,
}) => (
  <div className={classes.wrapper}>
    <PageTitle
      withGoBack
      onGoBackClick={onGoBack}
    >
      {intl.formatMessage(messages.gradeData)}
    </PageTitle>
    <Paper>
      <div className={classes.contentWrapper}>
        <Formik
          initialValues={
            { ...initialValues }
          }
          validate={(values) => validator(values, validators, intl.formatMessage)}
          onSubmit={onGenerate}
          render={({
            values,
            errors,
            touched,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className={classes.inputWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="gradeName"
                  label={intl.formatMessage(messages.insertGradeName)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    className={classes.textField}
                    value={values.gradeName}
                    labelWidth={135}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.inputWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="count"
                  label={intl.formatMessage(messages.respondersCount)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    className={classes.textField}
                    value={values.count}
                    labelWidth={110}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.generateLinksWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  fullWidth
                >
                  {intl.formatMessage(messages.generateLinks)}
                </Button>
              </div>
            </form>
          )}
        />
      </div>
    </Paper>
  </div>
);

GradeData.propTypes = {
  intl: intlShape.isRequired,
  onGenerate: PropTypes.func.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    gradeName: PropTypes.string,
    count: PropTypes.string,
  }),
  onGoBack: PropTypes.func,
};

GradeData.defaultProps = {
  initialValues: {},
  classes: {},
  onGoBack: () => {},
};

export default withStyles(gradeDataStyles)(injectIntl(GradeData));
