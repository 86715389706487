import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from 'config';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormDialog from 'components/FormDialog';

import { classesShape } from 'utils/shapes/classesShape';
import activeSurveysStyles from './ActiveSurveys.styles';
import messages from './ActiveSurveys.messages';

class ActiveSurveys extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    onActiveSurveyClick: PropTypes.func.isRequired,
    onActiveSurveyFinish: PropTypes.func.isRequired,
    activeSurveys: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      organizationUnit: PropTypes.string,
    })),
    classes: classesShape,
  };

  static defaultProps = {
    activeSurveys: [],
    classes: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      dialogOpenedForId: null,
    };
  }

  onFinishSurvey = (id) => {
    this.setState({
      dialogOpen: true,
      dialogOpenedForId: id,
    });
  }

  onCloseClick = () => {
    this.setState({
      dialogOpen: false,
      dialogOpenedForId: null,
    });
  }

  onPinSubmit = (values, methods) => {
    if (values.pin === config.closeSurveyPin) {
      this.props.onActiveSurveyFinish(this.state.dialogOpenedForId);
      methods.resetForm();
      this.onCloseClick();
    } else {
      methods.setFieldError('pin', this.props.intl.formatMessage(messages.pinIncorrect));
      methods.setFieldValue('pin', '', false);
    }
  }

  render() {
    const {
      classes, activeSurveys, onActiveSurveyClick, intl,
    } = this.props;

    return (
      <>
        {
          activeSurveys.map((el) => (
            <div className={classes.launchedSurvey} key={el.id}>
              <Typography color="textSecondary" variant="caption">
                {
                  intl.formatMessage(messages.launchedSurvey, {
                    surveyName: el.name,
                    organizationUnit: el.organizationUnit,
                  })
                }
              </Typography>
              <div className={classes.buttonsWrapper}>
                <div className={classes.activeButtonWrapper}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classNames({
                      [classes.text]: true,
                    })}
                    onClick={() => onActiveSurveyClick(el.id)}
                  >
                    {intl.formatMessage(messages.go)}
                  </Button>
                </div>
                <Button
                  variant="outlined"
                  color="inherit"
                  className={classNames({
                    [classes.text]: true,
                  })}
                  onClick={() => this.onFinishSurvey(el.id)}
                >
                  {intl.formatMessage(messages.finish)}
                </Button>
              </div>
            </div>
          ))
        }
        <FormDialog
          title={intl.formatMessage(messages.enterPin)}
          label={intl.formatMessage(messages.pin)}
          onClose={() => { this.onCloseClick(); }}
          onSubmit={this.onPinSubmit}
          open={this.state.dialogOpen}
        />
      </>
    );
  }
}

export default withStyles(activeSurveysStyles)(injectIntl(ActiveSurveys));
