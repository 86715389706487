import React from 'react';
import { Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

// pages
import LoginPage from 'containers/Login';
import DashboardPage from 'containers/Dashboard';
import SurveysListPage from 'containers/SurveysList';
import GradeDataPage from 'containers/GradeData';
import SurveysLinksPage from 'containers/SurveysLinks';
import ForgotPasswordPage from 'containers/ForgotPassword';
import UserDataPage from 'containers/UserData';
import QuestionPage from 'containers/QuestionPage';
import SurveyFinished from 'containers/SurveyFinished';
import ResetPassword from 'containers/ResetPassword';

import { historyShape } from 'utils/shapes/router.shapes';

import AuthorizedRoute from './AuthorizedRoute';
import UnauthorizedRoute from './UnauthorizedRoute';
import Global from '../global';

const Routes = ({ history }) => (
  <React.Fragment>
    <Global history={history}>
      <Switch>
        <UnauthorizedRoute component={LoginPage} path="/login" />
        <UnauthorizedRoute component={ForgotPasswordPage} path="/forgotPassword" />
        <UnauthorizedRoute component={ResetPassword} path="/resetPassword/:token" />
        <AuthorizedRoute component={DashboardPage} path="/dashboard" />
        <AuthorizedRoute component={SurveysListPage} path="/surveysList" />
        <AuthorizedRoute component={GradeDataPage} path="/gradeData" />
        <AuthorizedRoute component={SurveysLinksPage} path="/surveysLinks" />
        <AuthorizedRoute component={UserDataPage} path="/userData" />
        <AuthorizedRoute component={QuestionPage} path="/questionPage" />
        <AuthorizedRoute component={SurveyFinished} path="/surveyFinished" />
        <Redirect
          to={{
            pathname: '/login',
            search: history.location.search,
          }}
        />
      </Switch>
    </Global>
  </React.Fragment>
);

Routes.propTypes = {
  history: historyShape.isRequired,
};

export default Routes;
