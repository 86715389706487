import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';
import UserDataPage from 'components/pages/UserData';
import { historyShape } from 'utils/shapes/router.shapes';

import { setTitle, setUniqueIdVisibility } from 'containers/store';

import { loadPageData, onSubmit } from './store';

class UserData extends React.Component {
  static propTypes = {
    history: historyShape.isRequired,
    intl: intlShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setUniqueIdVisibility: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setUniqueIdVisibility(true);
    this.props.setTitle('');
    this.props.loadPageData();
  }

  render() {
    return this.props.isLoadedPage ? (
      <UserDataPage
        {...this.props}
        goToSurvey={this.props.onSubmit}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  loadPageData,
  setTitle,
  onSubmit,
  setUniqueIdVisibility,
};

const mapStateToProps = (state) => ({
  ...state.UserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserData));
