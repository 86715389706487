import { push, goBack } from 'connected-react-router';
import _find from 'lodash/find';
import {
  showLoader, showTransparentLoader, hideLoader, showSnackbar, setSelectedLink,
} from 'containers/store';

import { clearQuestionsAndIndex } from 'containers/QuestionPage/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';

export const initialState = {
  isLoadedPage: false,
  items: [],
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEYS_LINKS/LOAD_PAGE_SUCCESS',
};

const generateItems = (items) => items.map((el, key) => ({
  id: key + 1,
  linkId: el.id,
  questionId: el.uniqueid,
  status: el.status,
  currentQuestion: el.current_questions_counter || 0,
  overallQuestion: el.overall_questions_counter || 0,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: generateItems(action.response.data),
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (response) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  response,
});

const loadLinks = (id) => (dispatch) => {
  const url = `session/${id}/surveys`;

  return ApiManager.request('get', dispatch, url);
};

export const onRefresh = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const store = getStore();
  const sessionId = store.Global.sessionId;

  dispatch(loadLinks(sessionId)).then((response) => {
    dispatch(loadPageSuccess(response));
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
  });
};

export const onStart = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const store = getStore();
  const items = store.SurveysLinks.items;
  const sessionId = store.Global.sessionId;

  ApiManager.request('get', dispatch, `session/${sessionId}/start`).then((response) => {
    dispatch(clearQuestionsAndIndex());
    const selectedLink = _find(items, (el) => el.questionId === response.data.uniqueid);
    dispatch(setSelectedLink(selectedLink));
    dispatch(push('/userData'));
    dispatch(hideLoader());
  }).catch((error) => {
    if (error.code === 423) {
      dispatch(showSnackbar(snackbarMessages.noAvailableSurveysToStart));
      dispatch(onRefresh());
      return;
    }

    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
  });
};

export const loadPageData = () => (dispatch, getStore) => {
  dispatch(showLoader());

  const store = getStore();
  const sessionId = store.Global.sessionId;

  dispatch(loadLinks(sessionId)).then((response) => {
    dispatch(loadPageSuccess(response));
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
    dispatch(goBack());
  });
};
