import React from 'react';
import PropTypes from 'prop-types';
import { push, goBack } from 'connected-react-router';
import _get from 'lodash/get';
import _find from 'lodash/find';
import { connect } from 'react-redux';
import SurveysListPage from 'components/pages/SurveysList';

import {
  setTitle, setSelectedSurvey, setUniqueIdVisibility,
} from 'containers/store';
import { loadPageData, onActiveSurveyClick, onActiveSurveyFinish } from './store';

class SurveysList extends React.Component {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    loadPageData: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    setSelectedSurvey: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    setUniqueIdVisibility: PropTypes.func.isRequired,
    organizationName: PropTypes.string,
  };

  static defaultProps = {
    organizationName: '',
  }

  componentDidMount() {
    this.props.setUniqueIdVisibility(false);
    this.props.setTitle(this.props.organizationName);
    this.props.loadPageData();
  }

  onSurveySelect = (id) => {
    const selectedSurvey = _find(this.props.items, (el) => el.surveyId === id);
    this.props.setSelectedSurvey(selectedSurvey);
    this.props.push('/gradeData');
  }

  render() {
    return this.props.isLoadedPage ? (
      <SurveysListPage
        {...this.props}
        onGoBack={this.props.goBack}
        onSurveySelect={this.onSurveySelect}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  loadPageData,
  setTitle,
  setSelectedSurvey,
  push,
  goBack,
  onActiveSurveyClick,
  onActiveSurveyFinish,
  setUniqueIdVisibility,
};

const mapStateToProps = (state) => ({
  ...state.SurveysList,
  organizationName: _get(state, 'Global.selectedOrganization.name', ''),
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveysList);
