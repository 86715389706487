export default () => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
  },
  goBackIconWrapper: {
    marginRight: 12,
  },
});
