import { push } from 'connected-react-router';
import _forEach from 'lodash/forEach';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import {
  showTransparentLoader, hideLoader, showLoader, showSnackbar, stopCounter,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

export const initialState = {
  isLoadedPage: false,
  questions: [],
  currentIndex: 0,
  savedValues: {},
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'QUESTION_PAGE/LOAD_PAGE_SUCCESS',
  INCREMENT_INDEX: 'QUESTION_PAGE/INCREMENT_INDEX',
  CLEAR_STORE_LOADED: 'QUESTION_PAGE/CLEAR_STORE_LOADED',
  CLEAR_QUESTIONS_AND_INDEX: 'QUESTION_PAGE/CLEAR_QUESTIONS_AND_INDEX',
  SET_INDEX: 'QUESTION_PAGE/SET_INDEX',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        questions: action.questions,
      };
    }

    case actionTypes.INCREMENT_INDEX: {
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
      };
    }

    case actionTypes.SET_INDEX: {
      return {
        ...state,
        currentIndex: action.newIndex,
      };
    }

    case actionTypes.CLEAR_STORE_LOADED: {
      return {
        ...state,
        isLoadedPage: false,
      };
    }

    case actionTypes.CLEAR_QUESTIONS_AND_INDEX: {
      return {
        ...state,
        currentIndex: 0,
        questions: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (questions) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  questions,
});

export const incrementIndex = () => ({
  type: actionTypes.INCREMENT_INDEX,
});

const setIndex = (newIndex) => ({
  type: actionTypes.SET_INDEX,
  newIndex,
});

const getQuestionsWithAnswers = (response) => {
  const questions = [];

  _forEach(response.data, (el) => {
    questions.push({
      id: el.question_id,
      mappingId: el.id,
      question: el.question.name,
      image: el.question.image || null,
      answers: el.question.answer_set.answers.map((el1) => ({
        id: el1.id,
        answer: el1.label,
      })),
      progressStatus: el.progress_status_label,
      isLast: el.is_last,
      answersMapping: el.answer.map((el1) => ({
        id: el1.id,
        nextQuestion: el1.pivot.next_survey_question_id,
      })),
    });
  });

  return questions;
};

export const finishSessions = () => (dispatch, getStore) => {
  const store = getStore();
  const sessionId = store.Global.sessionId;
  const body = {
    data: {
      uniqueid: store.Global.selectedLink.questionId,
      status: 'ENDED',
    },
  };

  return ApiManager.request('post', dispatch, `session/${sessionId}/survey`, body);
};

export const clearStore = () => ({
  type: actionTypes.CLEAR_STORE_LOADED,
});

export const clearQuestionsAndIndex = () => ({
  type: actionTypes.CLEAR_QUESTIONS_AND_INDEX,
});

const goToNextQuestion = (answerId, formikMethods) => (dispatch, getStore) => {
  const store = getStore().QuestionPage;
  const { currentIndex, questions } = store;

  const mapping = _find(questions[currentIndex].answersMapping, (el) => el.id === answerId);

  if (mapping) {
    const nextQuestionIndex = _findIndex(questions, (el) => el.mappingId === mapping.nextQuestion);
    dispatch(setIndex(nextQuestionIndex));
  } else {
    dispatch(incrementIndex());
  }

  formikMethods.resetForm();
};

export const onSubmit = (values, formikMethods) => (dispatch, getStore) => {
  const store = getStore();

  const sessionId = store.Global.sessionId;
  const uniqueId = store.Global.selectedLink.questionId;
  const currentIndex = store.QuestionPage.currentIndex;
  const questions = store.QuestionPage.questions;

  const body = {
    data: {
      uniqueid: uniqueId,
      answer_id: values.answer,
      session_id: sessionId,
      question_id: questions[currentIndex].id,
    },
  };

  dispatch(showTransparentLoader());

  ApiManager.request('post', dispatch, 'results', body).then(() => {
    if (currentIndex + 1 === questions.length || questions[currentIndex].isLast) {
      dispatch(finishSessions()).then(() => {
        dispatch(stopCounter());
        dispatch(push('/surveyFinished', { type: 'finished' }));
      }).catch((error) => {
        if (isBadRequest(error)) {
          dispatch(showSnackbar(snackbarMessages.wrongData));
        } else {
          dispatch(showSnackbar(snackbarMessages.globalError));
        }

        dispatch(hideLoader());
      });
    } else {
      dispatch(goToNextQuestion(values.answer, formikMethods));
    }

    dispatch(hideLoader());
  }).catch((error) => {
    if (error.code === null) { // no connection
      dispatch(push('/surveyFinished', { type: 'noConnection', savedValues: values, pages: 'questions' }));
    } else if (error.code === 418) { // times up
      dispatch(push('/surveyFinished', { type: 'timesUp' }));
    } else if (error.code === 423) {
      dispatch(push('/surveyFinished', { type: 'finished' }));
    } else if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const loadPageData = () => (dispatch, getStore) => {
  const store = getStore();

  dispatch(showLoader());

  const surveyId = store.Global.selectedSurvey.surveyId;
  const uniqueId = store.Global.selectedLink.questionId;

  ApiManager.request('get', dispatch, `survey/${surveyId}/questions/${uniqueId}`).then((response) => {
    const questions = getQuestionsWithAnswers(response);
    dispatch(loadPageSuccess(questions));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
