import { defineMessages } from 'react-intl';

export default defineMessages({
  available: 'nieaktywna',
  launched: 'uruchomiona',
  finished: 'zakończona',
  initialized: 'zainicjowana',
  surveyLinks: 'Linki ankiet',
  examined: 'Badany #',
  status: 'Stan',
  questionId: 'ID pytania',
  launch: 'Uruchom ankietę',
  refresh: 'Odśwież',
  surveyProgress: '{currentQuestion} z {overallQuestion}',
  progress: 'Postęp',
});
