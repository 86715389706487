import { defineMessages } from 'react-intl';
import _isNumber from 'lodash/isNumber';

const messages = defineMessages({
  fieldValueTooLarge: 'Wartość pola zbyt niska',
});

export default (value, minValue) => {
  const result = {};
  result.isValid = true;

  if (_isNumber(parseInt(value)) && _isNumber(minValue)) {
    result.isValid = value >= minValue;
    result.message = messages.fieldValueTooLarge;
  }

  return result;
};
