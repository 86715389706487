import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { classesShape } from 'utils/shapes/classesShape';
import validator from 'utils/validator/core';
import FormField from 'components/FormField';
import GenderPicker from 'components/GenderPicker';
import userDataStyles from './UserData.styles';

import messages from './UserData.messages';


const months = [
  '01 - Styczeń', '02 - Luty', '03 - Marzec', '04 - Kwiecień',
  '05 - Maj', '06 - Czerwiec', '07 - Lipiec', '08 - Sierpień', '09 - Wrzesień',
  '10 - Październik', '11 - Listopad', '12 - Grudzień',
];

const validators = [
  {
    fieldName: 'gender',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
  {
    fieldName: 'year',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
  {
    fieldName: 'month',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
  {
    fieldName: 'sameCity',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
  {
    fieldName: 'city',
    validateIf: (values) => values.sameCity === '0',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
];

const UserData = ({
  goToSurvey,
  classes,
  intl,
  initialValues,
  yearRangeFrom,
  yearRangeTo,
}) => {
  const yearMin = yearRangeFrom;
  const yearMax = yearRangeTo;
  const years = [];

  for (let i = yearMin; i <= yearMax; i += 1) {
    years.push(i);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.captionWrapper}>
        <Typography variant="h6">
          {intl.formatMessage(messages.chooseGender)}
        </Typography>
      </div>
      <Formik
        initialValues={
          { ...initialValues }
        }
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={goToSurvey}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={classes.genderPickerWrapper}>
              <FormField
                onBlur={setFieldTouched}
                onChange={setFieldValue}
                errors={errors}
                touched={touched}
                name="gender"
                variant="outlined"
                fullWidth
                passErrorStatus
              >
                <GenderPicker
                  selected={values.gender}
                />
              </FormField>
            </div>
            <div className={classes.birthWrapper}>
              <Typography variant="h6">
                {intl.formatMessage(messages.insertBirthData)}
              </Typography>
            </div>
            <div className={classes.birtPickersWrapper}>
              <FormField
                onBlur={setFieldTouched}
                onChange={setFieldValue}
                errors={errors}
                touched={touched}
                name="year"
                label={intl.formatMessage(messages.year)}
                variant="outlined"
                fullWidth
              >
                <Select
                  value={values.year}
                  input={(
                    <OutlinedInput
                      labelWidth={25}
                      name="year"
                      id="year-picker"
                    />
                  )}
                >
                  {
                    years.map((el) => (
                      <MenuItem value={el} key={el}>{el}</MenuItem>
                    ))
                  }
                </Select>
              </FormField>
              <div className={classes.monthPickerWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="month"
                  label={intl.formatMessage(messages.month)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.month}
                    input={(
                      <OutlinedInput
                        labelWidth={55}
                        name="month"
                        id="month-picker"
                      />
                    )}
                  >
                    {
                      months.map((el) => (
                        <MenuItem value={el} key={el}>{el}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
            </div>
            <div className={classes.cityWrapper}>
              <Typography variant="h6">
                {intl.formatMessage(messages.sameCity)}
              </Typography>
              <FormField
                onBlur={setFieldTouched}
                onChange={setFieldValue}
                errors={errors}
                touched={touched}
                name="sameCity"
                variant="outlined"
                fullWidth
              >
                <RadioGroup
                  value={values.sameCity}
                  className={classes.root}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage(messages.yes)}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label={intl.formatMessage(messages.no)}
                  />
                </RadioGroup>
              </FormField>
            </div>
            {
              values.sameCity === '0' ? (
                <div className={classes.cityInputWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="city"
                    label={intl.formatMessage(messages.city)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      value={values.city}
                      labelWidth={50}
                      margin="none"
                      variant="outlined"
                    />
                  </FormField>
                </div>
              ) : null
            }
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
            >
              {intl.formatMessage(messages.next)}
            </Button>
          </form>
        )}
      />
    </div>
  );
};

UserData.propTypes = {
  goToSurvey: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    gender: PropTypes.string,
    year: PropTypes.string,
    month: PropTypes.string,
    sameCity: PropTypes.string,
    city: PropTypes.string,
  }),
  yearRangeFrom: PropTypes.number,
  yearRangeTo: PropTypes.number,
};

UserData.defaultProps = {
  initialValues: {},
  classes: {},
  yearRangeFrom: null,
  yearRangeTo: null,
};

export default withStyles(userDataStyles)(injectIntl(UserData));
