import { defineMessages } from 'react-intl';

export default defineMessages({
  globalError: 'Wystąpił problem podczas wczytywania. Spróbuj ponownie później.',
  succesfullyResetedPassword: 'Link został wysłany',
  wrongData: 'Niepoprawne dane',
  logoutSuccess: 'Zostałeś pomyślnie wylogowany',
  passwordChanged: 'Hasło zostało zmienione',
  surveyFinished: 'Ankieta zakończona',
  surveyIsAlreadyActive: 'Masz już jedną aktywną ankietę',
  noAvailableSurveysToStart: 'Brak dostępnych ankiet',
});
