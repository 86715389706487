export default (theme) => ({
  tableWrapper: {
    marginTop: 12,
    backgroundColor: theme.palette.cardBackground,
  },
  numberCell: {
    width: 10,
    padding: 12,
  },
  buttonCell: {
    width: 95,
  },
  activeSurveysWrapper: {
    marginBottom: 24,
  },
});
