import { replace, push } from 'connected-react-router';
import ApiManager from 'utils/ApiManager'; // eslint-disable-line import/no-cycle
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

const initialState = {
  loaderVisible: false,
  transparentLoaderVisible: false,
  snackbarVisible: false,
  snackbarMessage: null,
  title: null,
  selectedOrganization: {},
  selectedSurvey: {},
  sessionId: null,
  selectedLink: {},
  intervalId: null,
  uniqueIdVisible: false,
  uniqueIdHidden: false,
};

export const actionTypes = {
  SHOW_LOADER: 'GLOBAL/SHOW_LOADER',
  SHOW_TRANSPARENT_LOADER: 'GLOBAL/SHOW_TRANSPARENT_LOADER',
  HIDE_LOADER: 'GLOBAL/HIDE_LOADER',
  SHOW_SNACKBAR: 'GLOBAL/SHOW_SNACKBAR',
  HIDE_SNACKBAR: 'GLOBAL/HIDE_SNACKBAR',
  SET_TITLE: 'GLOBAL/SET_TITLE',
  SET_SELECTED_ORGANIZATION: 'GLOBAL/SET_SELECTED_ORGANIZATION',
  SET_SELECTED_SURVEY: 'GLOBAL/SET_SELECTED_SURVEY',
  SET_SESSION_ID: 'GLOBAL/SET_SESSION_ID',
  SET_SELECTED_LINK: 'GLOBAL/SET_SELECTED_LINK',
  SET_INTERVAL: 'GLOBAL/SET_INTERVAL',
  SET_UNIQUE_ID_VISIBILITY: 'GLOBAL/SET_UNIQUE_ID_VISIBILITY',
  SET_UNIQUE_ID_HIDDEN: 'GLOBAL/SET_UNIQUE_ID_HIDDEN',
  CLEAR_APP_STORE: 'GLOBAL/CLEAR_APP_STORE',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADER: {
      return {
        ...state,
        loaderVisible: true,
        transparentLoaderVisible: false,
      };
    }

    case actionTypes.SHOW_TRANSPARENT_LOADER: {
      return {
        ...state,
        loaderVisible: false,
        transparentLoaderVisible: true,
      };
    }

    case actionTypes.HIDE_LOADER: {
      return {
        ...state,
        loaderVisible: false,
        transparentLoaderVisible: false,
      };
    }

    case actionTypes.SHOW_SNACKBAR: {
      return {
        ...state,
        snackbarVisible: true,
        snackbarMessage: action.message,
      };
    }

    case actionTypes.HIDE_SNACKBAR: {
      return {
        ...state,
        snackbarVisible: false,
        snackbarMessage: null,
      };
    }

    case actionTypes.SET_TITLE: {
      return {
        ...state,
        title: action.title,
      };
    }

    case actionTypes.SET_SELECTED_ORGANIZATION: {
      return {
        ...state,
        selectedOrganization: action.organization,
      };
    }

    case actionTypes.SET_SELECTED_SURVEY: {
      return {
        ...state,
        selectedSurvey: action.survey,
      };
    }

    case actionTypes.SET_SESSION_ID: {
      return {
        ...state,
        sessionId: action.sessionId,
      };
    }

    case actionTypes.SET_SELECTED_LINK: {
      return {
        ...state,
        selectedLink: action.link,
      };
    }

    case actionTypes.SET_INTERVAL: {
      return {
        ...state,
        intervalId: action.intervalId,
      };
    }

    case actionTypes.SET_UNIQUE_ID_VISIBILITY: {
      return {
        ...state,
        uniqueIdVisible: action.isUniqueIdVisible,
      };
    }

    case actionTypes.SET_UNIQUE_ID_HIDDEN: {
      return {
        ...state,
        uniqueIdHidden: action.isUniqueIdHidden,
      };
    }

    default:
      return state;
  }
};

export const showLoader = () => ({
  type: actionTypes.SHOW_LOADER,
});

export const showTransparentLoader = () => ({
  type: actionTypes.SHOW_TRANSPARENT_LOADER,
});

export const hideLoader = () => ({
  type: actionTypes.HIDE_LOADER,
});

export const showSnackbar = (message) => ({
  type: actionTypes.SHOW_SNACKBAR,
  message,
});

export const hideSnackbar = () => ({
  type: actionTypes.HIDE_SNACKBAR,
});

export const clearAppStore = () => ({
  type: actionTypes.CLEAR_APP_STORE,
});

export const logout = () => (dispatch) => {
  dispatch(showTransparentLoader());

  return new Promise((resolve, reject) => {
    ApiManager.request('post', dispatch, 'logout', {}).then(() => {
      dispatch(hideLoader());
      dispatch(showSnackbar(snackbarMessages.logoutSuccess));
      dispatch(replace('/login'));
      dispatch(clearAppStore());
      resolve();
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
      reject();
    });
  });
};

export const setTitle = (title) => ({
  type: actionTypes.SET_TITLE,
  title,
});

export const setSelectedOrganization = (organization) => ({
  type: actionTypes.SET_SELECTED_ORGANIZATION,
  organization,
});

export const setSelectedSurvey = (survey) => ({
  type: actionTypes.SET_SELECTED_SURVEY,
  survey,
});

export const setSessionId = (sessionId) => ({
  type: actionTypes.SET_SESSION_ID,
  sessionId,
});

export const setSelectedLink = (link) => ({
  type: actionTypes.SET_SELECTED_LINK,
  link,
});

const setIntervalId = (intervalId) => ({
  type: actionTypes.SET_INTERVAL,
  intervalId,
});

export const stopCounter = () => (dispatch, getStore) => {
  const store = getStore().Global;
  clearInterval(store.intervalId);
  dispatch(setIntervalId(null));
};

export const startCounting = (time) => (dispatch) => {
  const finishAt = new Date().getTime() + (time * 1000);

  const intervalId = setInterval(() => {
    const now = new Date().getTime();

    if (now >= finishAt) {
      dispatch(stopCounter());
      dispatch(push('/surveyFinished', { type: 'timesUp' }));
    }
  }, 1000);

  dispatch(setIntervalId(intervalId));
};

export const setUniqueIdVisibility = (isUniqueIdVisible) => ({
  type: actionTypes.SET_UNIQUE_ID_VISIBILITY,
  isUniqueIdVisible,
});

export const setUniqueIdHidden = (isUniqueIdHidden) => ({
  type: actionTypes.SET_UNIQUE_ID_HIDDEN,
  isUniqueIdHidden,
});
