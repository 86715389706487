import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import validator from 'utils/validator/core';
import { classesShape } from 'utils/shapes/classesShape';
import questionPageStyles from './QuestionPage.styles';

import messages from './QuestionPage.messages';

const validators = [
  {
    fieldName: 'answer',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
];

const QuestionPage = ({
  classes, answers, question, onSubmit, intl, image, progressStatus,
}) => (
  <div className={classes.wrapper}>
    <Typography variant="h6">
      {intl.formatMessage(messages.question)}
    </Typography>
    <div className={classes.questionWrapper}>
      <Typography variant="h6">
        { /* eslint-disable-next-line react/no-danger */ }
        <span dangerouslySetInnerHTML={{ __html: question }} />
      </Typography>
    </div>
    {
      image ? (
        <Card className={classes.cardImage}>
          <CardMedia
            className={classes.media}
            image={image}
          />
        </Card>
      ) : null
    }
    <Formik
      initialValues={{
        answer: null,
      }}
      validate={(values) => validator(values, validators, intl.formatMessage)}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.answersWrapper}>
            <Typography variant="h6">
              {intl.formatMessage(messages.chooseAnswer)}
            </Typography>
            {
              answers.map((el) => (
                <div
                  className={classNames({
                    [classes.answerWrapper]: true,
                    [classes.activeAnswerWrapper]: values.answer === el.id,
                    [classes.errorAnswerWrapper]: !!(touched.answer && errors.answer),
                  })}
                  key={el.id}
                  onClick={() => {
                    setFieldTouched('answer', true);
                    setFieldValue('answer', el.id);
                  }}
                >
                  {
                    values.answer === el.id ? (
                      <div className={classes.checkWrapper}>
                        <CheckCircleIcon color="primary" />
                      </div>
                    ) : null
                  }
                  <Typography variant="h6">
                    {el.answer}
                  </Typography>
                </div>
              ))
            }
          </div>
          {
            touched.answer && errors.answer ? (
              <div className={classes.errorMessage}>
                <Typography variant="caption" className={classes.caption}>
                  {errors.answer}
                </Typography>
              </div>
            ) : null
          }
          <div className={classes.submitWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
            >
              {intl.formatMessage(messages.next)}
            </Button>
          </div>
        </form>
      )}
    />
    {
      progressStatus ? (
        <div className={classes.progressStatusWrapper}>
          <Typography variant="subtitle1">
            {progressStatus}
          </Typography>
        </div>
      ) : null
    }
  </div>
);

QuestionPage.propTypes = {
  intl: intlShape.isRequired,
  question: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    answer: PropTypes.string,
  })),
  classes: classesShape,
  image: PropTypes.string,
  progressStatus: PropTypes.string,
};

QuestionPage.defaultProps = {
  answers: [],
  classes: {},
  image: '',
  progressStatus: '',
};

export default withStyles(questionPageStyles)(injectIntl(QuestionPage));
