import { replace } from 'connected-react-router';
import {
  showTransparentLoader,
  hideLoader,
  showLoader,
  showSnackbar,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

export const initialState = {
  isLoadedPage: false,
  apiVersion: null,
  initialValues: {
    login: '',
    password: '',
  },
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'LOGIN_PAGE/LOAD_PAGE_SUCCESS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        apiVersion: action.apiVersion,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (apiVersion) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  apiVersion,
});

export const login = (values) => (dispatch) => {
  dispatch(showTransparentLoader());

  const requestBody = {
    data: {
      email: values.login,
      password: values.password,
    },
  };

  ApiManager.request('post', dispatch, 'login', requestBody).then(() => {
    dispatch(hideLoader());
    dispatch(replace('/dashboard'));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const loadPageData = () => (dispatch) => {
  dispatch(showLoader());

  ApiManager.request('get', dispatch, 'ping', null, true).then((response) => {
    const apiVersion = response.headers.version;

    dispatch(hideLoader());
    dispatch(loadPageSuccess(apiVersion));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
