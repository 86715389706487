import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import _throttle from 'lodash/throttle';
import { rootReducer } from 'containers/reducers';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('diagmaticState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('diagmaticState', serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

export default function initStore(history) {
  const middleware = [
    thunkMiddleware,
    routerMiddleware(history),
  ];

  const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

  const peristedState = loadState();

  const store = createStore(
    rootReducer(history),
    peristedState,
    composedEnhancers,
  );

  store.subscribe(_throttle(() => {
    saveState(store.getState());
  }, 1000));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../containers/reducers', () => {
      const nextReducers = require('../containers/reducers'); // eslint-disable-line global-require
      store.replaceReducer(nextReducers);
    });
  }

  return store;
}
