export default () => ({
  wrapper: {
    width: 468,
    margin: 'auto',
  },
  captionWrapper: {
    textAlign: 'center',
  },
  birtPickersWrapper: {
    marginTop: 36,
    marginBottom: 36,
  },
  genderPickerWrapper: {
    marginTop: 12,
  },
  genderSelection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  birthWrapper: {
    marginTop: 48,
    textAlign: 'center',
  },
  monthPickerWrapper: {
    marginTop: 36,
  },
  cityWrapper: {
    marginBottom: 36,
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  cityInputWrapper: {
    marginBottom: 36,
  },
});
