import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import _range from 'lodash/range';
import _forEach from 'lodash/forEach';

import { classesShape } from 'utils/shapes/classesShape';
import pinPadStyles from './PinPad.styles';

const numbers = [..._range(1, 10), 0];

const PinPad = ({ classes, onClick }) => {
  const buttons = [];

  _forEach(numbers, (el) => {
    buttons.push(
      <Button
        key={el}
        variant="contained"
        color="primary"
        size="small"
        className={classes.buttonWrapper}
        onClick={() => onClick(el)}
      >
        {el}
      </Button>,
    );
  });

  return (
    <div className={classes.wrapper}>
      {buttons}
    </div>
  );
};

PinPad.propTypes = {
  classes: classesShape.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(pinPadStyles)(PinPad);
