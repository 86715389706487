import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Dashboard',
  go: 'Przejdź',
  findSchool: 'Znajdź placówkę',
  schoolName: 'Nazwa placówki',
  address: 'Adres',
  from: 'z',
  filter: 'Wyszukaj',
  noResults: 'Brak wyników wyszukiwania',
});
