import { push } from 'connected-react-router';
import {
  hideLoader, showSnackbar, showTransparentLoader, setSessionId,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';


export const initialState = {
  initialValues: {
    gradeName: '',
    count: '',
  },
};

export const actionTypes = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const generateLinks = (values) => (dispatch, getStore) => {
  dispatch(showTransparentLoader());

  const store = getStore();

  const requestBody = {
    data: {
      organization_unit_code: values.gradeName,
      number_of_surveys: parseInt(values.count),
    },
  };

  const organizationid = store.Global.selectedOrganization.organizationId;
  const surveyId = store.Global.selectedSurvey.surveyId;

  const url = `organization/${organizationid}/survey/${surveyId}/session`;

  ApiManager.request('post', dispatch, url, requestBody).then((response) => {
    dispatch(setSessionId(response.data.id));
    dispatch(push('/surveysLinks'));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else if (error.code === 400) {
      dispatch(showSnackbar(snackbarMessages.surveyIsAlreadyActive));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
