import { push, goBack } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

import {
  hideLoader,
  showSnackbar,
  showTransparentLoader,
  startCounting,
  showLoader,
  setUniqueIdHidden,
} from 'containers/store';

export const initialState = {
  isLoadedPage: true,
  initialValues: {
    gender: null,
    year: '',
    month: '',
    sameCity: '1',
    city: '',
  },
  yearRangeTo: null,
  yearRangeFrom: null,
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'USER_DATA/LOAD_PAGE_SUCCESS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        yearRangeFrom: action.responses.data.participant_year_range_from,
        yearRangeTo: action.responses.data.participant_year_range_to,
      };
    }

    default:
      return state;
  }
};

const startSession = (body, sessionId) => (dispatch) => ApiManager.request('post', dispatch, `session/${sessionId}/survey`, body);

const saveData = (body) => (dispatch) => ApiManager.request('post', dispatch, 'session/participant', body);

const loadOrganizations = (organizationId) => (dispatch) => ApiManager.request('get', dispatch, `organization/${organizationId}/range`);

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

export const onSubmit = (values) => (dispatch, getStore) => {
  dispatch(showTransparentLoader());

  const store = getStore();

  const requestBody = {
    data: {
      user_birth_year: parseInt(values.year),
      user_birth_month: parseInt(values.month),
      user_sex: values.gender === 'male',
      uniqueid: store.Global.selectedLink.questionId,
      user_city: values.sameCity === '1' ? null : values.city,
    },
  };

  const sessionId = store.Global.sessionId;
  const setLinkAsStartedBody = {
    data: {
      uniqueid: store.Global.selectedLink.questionId,
      status: 'STARTED',
    },
  };

  PromiseAll({
    startSession: dispatch(startSession(setLinkAsStartedBody, sessionId)),
    saveData: dispatch(saveData(requestBody)),
  }).then((responses) => {
    dispatch(startCounting(responses.startSession.data.session.survey.max_time));
    dispatch(hideLoader());
    dispatch(push('/questionPage'));
  }).catch((error) => {
    if (error.code === null) { // no connection
      dispatch(push('/surveyFinished', { type: 'noConnection', userSavedValues: values, page: 'userData' }));
    } else if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const loadPageData = () => (dispatch, getStore) => {
  const store = getStore();
  const organizationId = store.Global.selectedOrganization.organizationId;

  dispatch(showLoader());
  dispatch(setUniqueIdHidden(false));
  dispatch(loadOrganizations(organizationId)).then((responses) => {
    dispatch(loadPageSuccess(responses));
    dispatch(hideLoader());
  }).catch(() => {
    dispatch(showSnackbar(snackbarMessages.globalError));
    dispatch(hideLoader());
    dispatch(goBack());
  });
};
