export default (theme) => ({
  launchedSurvey: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 6,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 5,
    marginBottom: 4,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  buttonsWrapper: {
    display: 'flex',
  },
  activeButtonWrapper: {
    marginRight: 4,
  },
});
